@import '../../styles/settings/variables';

$coverImageWidth--featured-main: 20.5rem;
$coverImageWidth--featured-regular: 15.3125rem;
$coverImageWidth--latest: 6.25rem;

.wrapperLink {
  text-decoration: none;
}

.featuredMain,
.latest {
  display: flex;
}

.featuredMain {
  .coverImage {
    width: $coverImageWidth--featured-main;
    height: calc(#{$coverImageWidth--featured-main} / 1.3);
    width: 20.5rem;
    height: 15.3125rem;
    object-fit: cover;
    border-radius: map-get($spacing, 'MD');
    margin-right: 1.25rem;
  }

  .title {
    margin-bottom: 0.625rem;
    padding-right: 0.25rem;
  }
}

.featuredRegular {
  margin: 1.25rem 2rem 1.25rem 0;
  width: 100%;

  @media screen and (min-width: map-get($breakpoints, 'XXL')) {
    max-width: calc(33% - 2rem);
  }
  @media screen and (max-width: map-get($breakpoints, 'XXL')) {
    max-width: calc(50% - 2rem);
  }
  @media screen and (max-width: map-get($breakpoints, 'XS')) {
    max-width: 100%;
  }

  .coverImageWrapper {
    position: relative;
    margin-bottom: 0.75rem;

    .newsPortalWrapper {
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
    }

    .coverImage {
      width: 100%;
      height: 11.5rem;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: map-get($spacing, 'MD');
    }
  }
}

.featuredMain,
.featuredRegular {
  .newsPortalIcon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: map-get($spacing, 'MD');
  }
}

.latest {
  padding: map-get($spacing, 'LG') 0;

  .coverImage {
    width: $coverImageWidth--latest;
    height: calc(#{$coverImageWidth--latest} / 1.3);
    object-fit: cover;
    border-radius: 0.125rem;
    margin-right: map-get($spacing, 'LG');
  }

  .title {
    margin-bottom: 0.25rem;
  }

  .textWrapper {
    padding-right: map-get($spacing, 'LG');
  }
}

.featuredMain,
.featuredRegular,
.latest {
  .title {
    &:hover {
      color: $primary;
    }
  }
}
