body {
  padding: 0;
  margin: 0;
  background-color: white;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}
