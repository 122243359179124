@import '../../styles/settings/variables';

$offset: 187;
$duration: 1.4s;

.loadingSpinner {
  width: 2.5rem;
  animation: rotator $duration linear infinite;

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .circle {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite;
    stroke: $primary;

    @keyframes dash {
      0% {
        stroke-dashoffset: $offset;
      }
      50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
      }
      100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
      }
    }
  }
}

.loader {
  width: 100%;
  height: 100vh;
  background-color: $gray200;

  .text {
    margin-top: 0.5rem;
  }
}
